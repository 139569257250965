
const Project = ({ data = { name: '', tech: [{ name: 'go' }] } }) => <section>
    <h1>{data.name}</h1>
    <div>
        {data.date && data.date.length > 0 && <div><strong>{data.date} </strong></div>}
        {data.role && data.role.length > 0 && <div><strong>Role: {data.role} </strong></div>}
    </div>
    {data.description && data.description.length &&
        <p>{data.description}</p>
    }
    {data.tech && <ul className="tag-list">
        {data.tech.map(t => <li key={t.name}>{t.name}</li>)}        
    </ul>}
    {data.tags && <ul className="tag-list">
        {data.tags.map(t => <li key={Math.random()}>{t}</li>)}        
    </ul>}
</section>


const projects = [
    {
        name: "patboyd.com",
        role: "Owner",
        date: "March 2021",
        description: "Create a fully integrated end to end website leveraging latest toolchain",
        tech: [
            {
                name: 'go'
            },
            {
                name: 'react'
            },
            {
                name: 'docker'
            },
            {
                name: 'k8s'
            },
            {
                name: 'GCP'
            },
        ]
    },
    {
        name: "Christmas tree star",
        date: "Dec 2021",
        description: "Create a programmable star for the christmas tree. Render star using sketchup, 3d print using PLA, code ESP-8266 IOT control module, assemble parts, deploy to tree, write react based control module, test.",
        tags: [
            'sketchup',
            '3d-printing',
            'arduino',
            'c++',
            'react'
        ]
    },
    
    {
        name: "IrisVR retina",
        role: "Principal engineer & architect",
        date: "2019",
        description: "Create a fully integrated end to end website leveraging latest toolchain",
        tech: [
            {
                name: 'go'
            },
            {
                name: 'react'
            },
            {
                name: 'docker'
            },
            {
                name: 'postgres'
            },
            {
                name: 'redis'
            },
            {
                name: 'windows containers'
            },
            {
                name: 'autodesk forge API'
            },
            {
                name: 'autodesk BIM360'
            },

            {
                name: 'GCP'
            },
        ]
    },
    {
        name: "YouVisit - ARIA viewer PWA",
        date: "Q4-2019",
    },
    {
        name: "YouVisit - ARIA Optimize",
        date: "Q4-2018",
    },
    {
        name: "Leaflift platform",
        date: "2015",
    },
    {
        name: "Developing lives",
        date: "2015",
    },
    {
        name: "Orange soda - xAPI adapter for AICC & SCORM",
        date: "2014",
    },
]
export const Projects = () => <div>
    {projects.map(entry => <Project key={entry.name} data={entry} />)}
</div>

export default Projects