import React from 'react';
import { Instagram, LinkedIn, RightNav } from './Header';

export const Footer = () =>
<>
<div style={{
      height: 50,
    }}></div>

  <footer style={{
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    background: 'white',
    borderTop: '1px solid black'
  }}>
    <div style={{
      margin: 8,
      paddingLeft: 45,
    }}>
      <Instagram />
      <span style={{ marginLeft: 5 }}>
        <LinkedIn />
      </span>
    </div>

    <div style={{
  position: 'fixed',
  fontSize: '0.6em',
  bottom: '0.5em',
  right: '0.5em',
}}>
<RightNav />
</div>
  </footer>
</>


export const Page = ({ user, onLogin, onLogout, onCreateAccount }) => (
  <div>
    <section>
      <p>
        
      I work with cloud technologies, 3d, XR, Photography, 3d Scanning<br/>

Building solutions to interersting problems. <br />
           Currently live in New York City with my wife, sons, 1 cat and 2 fish.
      </p>
    </section>

    <Footer />
  </div>
);