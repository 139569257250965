import React from 'react';
import './index.css';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

import Homepage from './components/homepage'
import Projects from './components/projects'
import About from './components/about'

import { Footer } from './components/homepage/Page'
import { Header } from './components/homepage/Header'

const App = () =>
  <div>
    <Header />    
    <Router>
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
     
        <Route path="*">
          <h1>Catych all</h1>
        </Route>
      </Switch>

    </Router>
    <Footer />
  </div>

export default App;
