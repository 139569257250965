

import './about.css'

export const About = () => <div>
    <section>
        <h2>Current Interests</h2>
        <ul className="tag-list">

            <li>go</li>
            <li>docker</li>
            <li>k8s</li>
            <li>react</li>
            <li>css</li>
            <li>threejs</li>
            <li>Unity</li>
            <li>Uunreal Engine</li>
            <li>GraphQL</li>
            <li>Websockets</li>
            <li>AWS</li>
            <li>GCP</li>
            <li>Azure</li>            
            <li>WebVR /VR / XR</li>            
        </ul>
    </section>

    <section>
        <h2>Positions</h2>
        <p>
            March 2021 - Current &mdash; <strong>Backend engineer</strong>, Unreal cloud services, Epic Games
        </p>
        <p>
            July 2019 - March 2021 &mdash; <strong>Lead backend engineer</strong>, IrisVR
        </p>
        <p>
            May 2017 - June 2019 &mdash; <strong>Fullstack Lead</strong>, Youvisit
        </p>


        
    </section>
</div>

export default About