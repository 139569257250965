import React from 'react';

export const LinkedIn = () => <a href="https://www.linkedin.com/in/patrick-boyd-a4a9204/" rel="noreferrer" target="_blank">
  <svg xmlns="http://www.w3.org/2000/svg" style={{ background: 'white', borderRadius: 7 }} width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" /></svg>
</a>

export const Instagram = () =>
  <a href="https://www.instagram.com/patboyd" rel="noreferrer" target="_blank">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ background: 'white', borderRadius: 7 }} width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg>
  </a>

const About = () =>
<a href="#/about">
About
</a>

const Projects = () =>
  <a href="#/projects">
Projects
  </a>


export const Header = ({ user, onLogin = () => {}, onLogout = () => {}, onCreateAccount = () => {} }) => (
  <>
  <header style={{
    position: '',
    top: 0,
    left: 0,
    right: 0,
    minHeight: 60,
    background: '#f0f0f0',
  }}>
    <div className="wrapper">
      <div style={{
        float:'left',
      }}>
        <a href="/">
          <span className="page-title">Patrick Boyd<br /><span style={{ fontSize: '0.3em', fontWeight: 600, lineHeight: '.5em' }}>Software Engineer / Technology Enthusiast</span> </span>
        </a>
      </div>
      <div style={{
  float: 'right',
  display: 'block',
  fontSize: '0.6em',
  marginTop: 20,
}}>
      <RightNav />
</div>
    </div>

  </header>
  <header style={{
    height: 65,
    background: 'rgba(0,0,0,.2)',
    display: 'none',
  }}>
    
  </header>
  </>
);

export const RightNav = () => <span>
  {/* <Navigation /> */}
  <div style={{
    // display: "inline-block",
    marginLeft: 0,
  }}>
    <div style={{
      float: 'left',
      marginRight: 15,
      height: 24,
      lineHeight: '1.8em',
    }}>
      <Projects />
    </div>
    <div style={{
      float: 'left',
      marginRight: 15,
      height: 24,
      lineHeight: '1.8em',
    }}>
      <About />
    </div>
  </div>
</span>